.App {
  text-align: center;
}

.App-header {
  height: 14vh;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  padding-bottom: 20px;
}

.App-body {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.App-body > p {
  font-size: 8em;
}

#logo {
  width: 160px;
  height: auto;
  z-index: 1;
}

.inputMessage {
  height: 20px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px #c7c7c7 solid;
    margin-right: 5px;
}

.inputColor {
  border-radius: 5px;
}

.buttonstart {
      height: 40px;
    width: 80px;
    border-radius: 10px;
    font-weight: bold;
    color: #fff;
    background-color: #1fc559;
    cursor: pointer;
}

.buttonstop {
      height: 40px;
    width: 80px;
    border-radius: 10px;
    font-weight: bold;
    color: #fff;
    background-color: #c51f59;
    cursor: pointer
}

.footer {
  color: #d2d2d2;
  text-align: center;
}
